import { Link } from "react-router-dom";
import SEOCookie from "./SEOCookie";
import './footer.css'
import { connect } from 'react-redux';
import { addFooterMenu } from '../actions';
import { deleteFooterMenu } from '../actions';
import FooterSlider from "./footerSlider";

const Footer = (props) => {   
  const footerMenuList =  localStorage.getItem("footerMenuList")
   ? JSON.parse(localStorage.getItem("footerMenuList"))
   : [];
  return (
    <div className="footer">
      <div className="custom-auto-space">
        <FooterSlider footerMenuData={footerMenuList} />
        <div className="row footer-main-menu">
          <div className="col-md-4">
            <Link to="/" area-lable="Money tree Robo Logo">
              <div className="footer-logo-container">
                <div>
                  <img
                    src={require("../images/moneytree-footer-logo.png").default}
                    alt="Moneytree"
                    prop=""
                  />
                </div>
                <div className="text-container"><span>Money Tree</span> Robo
                  <br />
                  <i>Stock Screener </i></div>
              </div>
            </Link>
            <div className="mt-4">
              Stock screener is basically a tool to find the best probable stocks on given certain criteria out of complete list of all NSE stocks & BSE stocks.
            </div>
            <div className="footer-app-container">
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mntreecapitalalrobopro.com"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Mntree Android Link"
                >
                  <img
                    src={require("../images/playstore.png").default} 
                    className="apprespnsive1"
                    alt="Google Play Store"
                    prop=""
                  />
                </a>
              </div>
              <div>
                <a href="/" target="_blank" rel="noreferrer" aria-label="Mntree Ios Link">
                  <img
                    src={require("../images/apple.png").default} 
                    className="apprespnsive"
                    alt="Apple Link"
                    prop=""
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <label>MoneyTree Robo</label>
            <ul>
              <li><Link to="/screener">Screener</Link></li>
              {/* <li><Link to="">Robo Portfolio</Link></li> */}
              <li><Link to="/portfolio-analysis">Portfolio Analysis</Link></li>
              <li><Link to="/portfolio">Portfolio</Link></li>
              <li><Link to="/watchlist">Watchlist</Link></li>
              <li><Link to="/nse-sectors-list">Sectors</Link></li>
              <li><Link to="/indian-industry-sector">Industry</Link></li>
              <li><Link to="/premium-services/futures-and-options">F&O</Link></li>
              <li><Link to="/premium-services">PRO+</Link></li> 
              <li><Link to="/support-and-target">Support And Target</Link></li>
              <li><Link to="/trading-tools">Trading Tools</Link></li>
              <li><Link to="/hourly-gainer-looser">Hourly Gainers</Link></li>
              <li><Link to="/market-signal">Nifty Signal</Link></li>
            </ul>
          </div>

          <div className="col-md-3">
            <label>Todays`s Market</label>
            <ul>
              <li><Link to="/nse-pre-market">Pre Market</Link></li>
              <li><Link to="/top-gainers-nse">Top Gainers</Link></li>
              <li><Link to="/top-losers-nse">Top Losers</Link></li>
              <li><Link to="/52-week-high-stocks">52 Week High</Link></li>
              <li><Link to="/52-week-low-stocks">52 Week Low</Link></li>
              <li><Link to="/breakout-stocks">Delivery Breakout</Link></li> 
            </ul>
          </div>

          <div className="col-md-2">
            <label>Features</label>
            <ul>
              <li><Link to="/faq">FAQs</Link></li>
              <li><Link to="/how-to-use">Tutorials</Link></li>
              {/* <li><Link  to="">Online Courses</Link></li> */}
              <li><a target="_blank" href="https://investtrad.com/" rel="noreferrer">Blog</a></li>
              <li><Link to="/fundamental-analysis">Fundamental Analysis</Link></li>
              <li><Link to="/technical-analysis">Technical Analysis</Link></li>
              <li><Link to="/video-course">Video Courses</Link></li> 
              <li><Link to="/basics-of-stock-market">Basics of Stock Market</Link></li>
            </ul>
          </div> 
        </div>

      </div>

      <div className="social-follow">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="social-link-list">
                <span>Follow us on: </span>
                <a
                  href="https://www.facebook.com/pg/Money-Tree-Robo-866994610159140/posts/"
                  target="blank"
                  aria-label="Facebook"
                  rel="noreferrer"
                >
                  <i className="fa fa-facebook "> </i>
                </a>
                <a
                  href="https://twitter.com/Money_tree_robo?s=03 India's "
                  target="blank"
                  aria-label="Twitter"
                  rel="noreferrer"
                >
                  <i className="fa fa-twitter ">                      </i>
                </a>
                <a
                  href="https://in.pinterest.com/money4608/money-tree-robo-pro/"
                  target="blank"
                  aria-label="Pinterest"
                  rel="noreferrer"
                >
                  <i className="fa fa-pinterest ">
                  </i>
                </a>
              </div>

            </div>
            <div className="col-md-8 text-right">
            <ul className="agileits_w3layouts_footer_info">  
              <li>
                <Link to="/terms-and-condition">Terms and Condition</Link>
                <i>|</i>
              </li>
              <li>
                <Link to="/refund-policy/">Refund Policy</Link>
                <i>|</i>
              </li>
              <li>
                <Link to="/privacy-policy/">Privacy Policy</Link>
                <i>|</i>
              </li>
              <li>
                <Link to="/about">About Us</Link>
                {/* <i>|</i> */}
              </li>
            </ul>
            </div>
          </div>
        </div>
      </div>



      <div className="  text-center">
        <SEOCookie />
        <p>
          © 2021 Stock Market. All rights reserved | Powered by
          <Link to="/">Moneytree</Link>
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    footerMenu: state
  };
};
const mapDispatchToProps = dispatch => {
  return {
     onaddFooterMenu: footerMenu => {
        dispatch(addFooterMenu(footerMenu));
     },
     onDelete: id => {
        dispatch(deleteFooterMenu(id));
     }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);

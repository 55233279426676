import { v4 as uuidv4 } from 'uuid';
import { ADD_FOOTERMENU, DELETE_FOOTERMENU } from './types';

export const addFooterMenu = ({ footerData }) => ({
    type: ADD_FOOTERMENU,
    payload: {
       id: uuidv4(),
       footerData
    }
 });


 export const deleteFooterMenu = id => ({
    type: DELETE_FOOTERMENU,
    payload: {
       id
    }
 });
import CookieConsent from "react-cookie-consent";
import { useState } from "react";

export default function SEOCookie() {
  const [showCookie, setShowCookie] = useState(false);
  setTimeout(function () {
    setShowCookie(true);
  }, 5000);
  return (
    <>
      {showCookie && (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="mntreecapitalCookie"
          style={{
            textAlign: "left",
            background: "rgb(7 7 7 / 80%)",
          }}
          buttonStyle={{
            color: "#fff",
            background: "#28a745",
            cursor: "pointer",
            borderRadius: ".25rem",
          }}
          expires={365}
          enableDeclineButton
          declineButtonText="Decline"
          declineButtonStyle={{
            background: "rgb(244, 67, 54)",
            color: "#fff",
            borderRadius: ".25rem",
          }}
        >
          We use cookies to ensure that we give you the best experience on our
          website. If you continue to use this site we will assume that you are
          happy with it.
        </CookieConsent>
      )}
    </>
  );
}
